import { Request } from '@/http/request'

// 语音统计
export function count(parameter: any){
  return Request.axiosInstance.post('/open/voice-count/count', parameter)
}
// 语音统计
export function list(parameter: any){
    return Request.axiosInstance.post('/open/voice-count/list', parameter)
  }

export default { count, list }